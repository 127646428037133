import { Injectable } from '@angular/core';
import { CookieService } from './cookie';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  //loginUrl: string = "https://accounts.totalcheck.cl/login?provider=alfresco&redirect=https://search.totalcheck.cl"
  loginUrl: string = "https://cloud.totalcheck.cl/accounts/login?provider=alfresco&redirect=https://search.totalcheck.cl"
  adminUsers : string[] = [
    "michel.david",
    "vnunez@totalcheck.cl",
    "gdemiguel@totalcheck.cl",
    "vnunez"
  ]
  constructor(
    private cookieServ: CookieService,
    private router: Router,
  ) { }

  isAuthenticated() {
    if (this.cookieServ.getCookie("ticket") == "") {
      location.href = this.loginUrl
    }
  }

  isAdmin(){     
    return this.adminUsers.filter(u=>u==this.cookieServ.getCookie("username")).length > 0
  }

  goTo(page) {
    this.router.navigate([page]);
  }
  
  goToWithId(page, parameter) {
    this.router.navigate([page, parameter]);
  }

  goToWithParam(page, parameter) {
    this.router.navigate(
      [page], 
      {
        //relativeTo: this.activatedRoute,
        queryParams: parameter,
        queryParamsHandling: 'merge'
      });
  }
}
