import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoxService, DoxReport } from '../services/doxSigner'
import { UserService } from '../services/user.service'
import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Component({
  selector: 'app-dox-signer',
  templateUrl: './dox-signer.component.html',
  styleUrls: ['./dox-signer.component.css']
})
export class DoxSignerComponent implements OnInit {

  section: string = ""
  node: DoxReport = {
    config: {
      DIRECCION: "",
      EMAIL: "",
      FIRMADO: "",
      FONO: "",
      RAZONSOCIAL: "",
      STAMPER: ""
    },
    tenant: "",
    client: "",
    version: "",
    path: "",
  }
  nodeArray: Object = {}
  reports: Observable<DoxReport[]>;
  data: DoxReport[] = [];

  constructor(
    private route: ActivatedRoute,
    private doxSrv: DoxService,
    private userServ: UserService,
    public auth: AngularFireAuth
  ) { }

  ngOnInit() {
    //this.userServ.isAuthenticated()
    this.route.params.subscribe(params => {
      this.section = params['id'];
      console.log(this.section)
      this.auth.auth.onAuthStateChanged((user) => {
        if (user) {
          this.reports = this.doxSrv.getAll()
          this.reports.subscribe(result => {
            this.data = result
          });
        } else {
          this.auth.auth.signInWithPopup(new auth.GoogleAuthProvider());
        }
      })
    })
  }

  prepareNode(node) {
    this.node = node;
    this.nodeArray = Object.entries(node.config);
  }

  async editNode() {
    console.dir(await this.doxSrv.update(this.node["id"], this.node));
  }

  async addNode() {
    console.dir(await this.doxSrv.addItemById(this.node["tenant"], this.node));
  }

  clean() {
    this.node = {
      config: {
        DIRECCION: "",
        EMAIL: "",
        FIRMADO: "",
        FONO: "",
        RAZONSOCIAL: "",
        STAMPER: ""
      },
      tenant: "",
      client: "",
      version: "",
      path: "",
    }
  }
}
