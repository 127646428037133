import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FireModel } from './model';


export interface RPSDBOTReport {
    cliente: string;
    folio: string;
    notaria: string;
    repertorio: string;
    state: string;
}

@Injectable({
    providedIn: 'root'
})

export class RPSDBotService extends FireModel<RPSDBOTReport>{
    constructor(
        public db: AngularFirestore
    ) {
        super('RPSDTasks', db)
    }

    toString() {
        return ""
    }

    toJson() {
        return {}
    }
}
