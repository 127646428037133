import { Component, OnInit } from '@angular/core';
import { AlfrescoService } from '../services/alfresco'
import { CookieService } from '../services/cookie'
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { element } from 'protractor';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  textToSearch: string = ""
  notFound: boolean = false
  nodes = [];
  node = {};
  //loginUrl: string = "https://accounts.totalcheck.cl/login?provider=alfresco&redirect=https://search.totalcheck.cl"
  loginUrl: string = "https://cloud.totalcheck.cl/accounts/login?provider=alfresco&redirect=https://search.totalcheck.cl"

  isSearching: boolean = false
  loading: boolean = false

  constructor(
    private alfServ: AlfrescoService,
    private cookieServ: CookieService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.isAuthenticated()
    this.route.queryParams.subscribe((queryParam: any) => {
      if (queryParam['textToSearch'] && this.textToSearch == "") {
        this.textToSearch = queryParam['textToSearch'];
        this.alfrescoSearch()
      }
    });
  }

  alfrescoSearch() {
    if (this.textToSearch != "") {
      this.isSearching = true
      this.loading = true
      this.alfServ.search(this.textToSearch).subscribe((resultText: string) => {
        this.loading = false
        if (resultText.includes("operacion no encontrada")) {
          this.nodes = []
          this.notFound = true;
          return
        }
        const result = JSON.parse(resultText)
        if (result.status && result.status.code != 200) {
          location.href = this.loginUrl
        }
        this.notFound = false;
        this.nodes = result.map(({ properties, files, tasks, workflows }, index) => {
          let node = { properties, files, tasks, workflows, workflow: {} }
          if (tasks[0]) {
            this.alfServ.task(tasks[0]['{http://www.alfresco.org/model/content/1.0}name']).subscribe(tasktext => {
              const task = JSON.parse(tasktext)
              //console.log(task)
              if (task.data && task.data.workflowInstance.id) {
                this.alfServ.workflow(task.data.workflowInstance.id).subscribe(workflow => {
                  //console.log(JSON.parse(workflow))
                  this.nodes[index].workflows = JSON.parse(workflow).data
                  //console.log(this.nodes)
                })
              }
            })
          }

          return {
            properties: properties,
            propertyKeys: Object.keys(properties),
            files: files,
            tasks: tasks,
            workflows: workflows,
            state: this.alfServ.getEstadoPrenda(node, tasks)
          }
        })
        console.log(result)
      })
    } else {
      console.log("no text")
    }
  }

  isAuthenticated() {
    this.alfServ.isValidUsers().subscribe(result => {
      if (this.cookieServ.getCookie("ticket") == "" || result.indexOf("Unauthorized") != -1) {
        location.href = this.loginUrl
      }
    })

  }

  prepareNode(item) {
    this.node = item;
  }

  objectKeys(object: Object) {
    return Object.keys(object)
  }

  print(anything) {
    if (anything && anything.zone) {
      return anything.date + "/" + (anything.month + 1) + "/" + (anything.year % 100) + " - " + anything.hours + ":" + anything.minutes
    } else if (anything && anything.userName) {
      return anything.userName
    }
    return anything
  }

  printKey(key: string) {
    return key
      .replace("{http://www.alfresco.org/model/system/1.0}", "sys:")
      .replace("{http://www.dox.cl/model/content/common/1.0}", "tc:")
      .replace("{http://www.dox.cl/model/content/mandatos/1.0}", "mnd:")
      .replace("{http://www.alfresco.org/model/content/1.0}", "cm:")
      .replace("{http://www.dox.cl/model/workflow/wfprd/2.0}", "wfprd2:")
      .replace("{http://www.dox.cl/model/utils/workflow/1.0}", "dwfutil:")
  }

}
