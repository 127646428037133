import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FireModel } from './model';


export interface Report { 
  report: Object[];
}

@Injectable({
  providedIn: 'root'
})

export class ReportService extends FireModel<Report>{
  constructor(
    public db: AngularFirestore
  ) { 
    super('fei-mandato-report',db)
  }

  toString() {
    return ""
  }

  toJson() {
    return {}
  }
}
