import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


export abstract class FireModel<T> {
    private itemsCollection: AngularFirestoreCollection<T>;
    items: Observable<T[]>;
    private itemDoc: AngularFirestoreDocument<T>;
    item: Observable<T>;

    constructor(public collection: string, protected afs: AngularFirestore) {
        
    }

    getAll() {
        //return this.afs.collection<T>(this.collection).valueChanges();

        return this.afs.collection<T>(this.collection).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();//as T
                data["id"] = a.payload.doc.id;
                return data;
            });
        }));
    }

    getById(id) {
        return this.afs.doc<T>(this.collection + '/' + id).valueChanges()
    }

    getBy(prop, value) {
        //this.collectionsREF = this.db.list(this.collection, ref => ref.orderByChild(prop).equalTo(value))
        return this.afs.collection(this.collection, ref => ref.where(prop, "==", value)).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();//as T
                data["id"] = a.payload.doc.id;
                return data;
            });
        }))
        /**
         * .pipe(
            map(actions => actions.map(a => {
                const data = a.payload.toJSON();
                const id = a.payload.key;
                return { id, ...data };
            })))
         */
    }

    addItem(item: T) {
        return this.afs.collection(this.collection).add(item)
    }

    addItemById(id:string , item: T) {
        return this.afs.doc(this.collection + "/" + id).set(item);
    }

    update(id: string, item) {
        console.log(this.collection + "/" + id)
        console.dir(item)
        return this.afs.doc(this.collection + "/" + id).update(item);
    }

    remove(id: string, item) {
        return this.afs.doc(this.collection + "/" + id).delete();
    }
}

export abstract class Model {
    collection: string = ''
    collectionsREF: AngularFireList<any>;    // Reference to Student data list, its an Observable
    collectionREF: AngularFireObject<any>;   // Reference to Student object, its an Observable too
    constructor(
        public collect: string,
        public db: AngularFireDatabase
    ) {
        this.collection = collect
    }

    getList() {
        this.collectionsREF = this.db.list(this.collection)
        return this.collectionsREF
    }
    getAll() {
        return this.getList().snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.toJSON();
                const id = a.payload.key;
                return { id, ...data };
            })))
    }

    getById(id) {
        this.collectionREF = this.db.object(this.collection + '/' + id)
        return this.collectionREF
    }

    getBy(prop, value) {
        //this.collectionsREF = this.db.list(this.collection, ref => ref.orderByChild(prop).equalTo(value))
        return this.db.list(this.collection, ref => ref.orderByChild(prop).equalTo(value)).snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.toJSON();
                const id = a.payload.key;
                return { id, ...data };
            })))
    }

    create(data) {
        return this.collectionsREF.push(data)
    }

    update(id, data) {
        this.collectionREF = this.db.object(this.collection + '/' + id)
        this.collectionREF.update(data)
    }

    delete(id: string) {
        this.collectionREF = this.db.object(this.collection + '/' + id)
        this.collectionREF.remove()
    }

    abstract toString(): string; // must be implemented in derived classes
    abstract toJson(): Object; // must be implemented in derived classes
}