import { Component, OnInit, ViewChild } from '@angular/core';
import { AlfrescoService } from '../services/alfresco'
import { CookieService } from '../services/cookie'
import { Observable } from 'rxjs';
import { ReportService, Report } from "../services/feiReport";
import { UserService } from '../services/user.service'
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { element } from 'protractor';
import * as $ from 'jquery'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  reports: Observable<Report[]>;
  result: Report[];
  //loginUrl: string = "https://accounts.totalcheck.cl/login?provider=alfresco&redirect=https://search.totalcheck.cl"
  loginUrl: string = "https://cloud.totalcheck.cl/accounts/login?provider=alfresco&redirect=https://search.totalcheck.cl"
  report: Report = { report: [] }

  constructor(
    private alfServ: AlfrescoService,
    private cookieServ: CookieService,
    private repServ: ReportService,
    private userServ: UserService,
    public auth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.userServ.isAuthenticated()
    this.auth.auth.onAuthStateChanged((user) => {
      if (user) {
        this.reports = this.repServ.getAll()
        this.reports.subscribe(result => {
          console.log(result, new Date().getDate() - 1)
          this.result = result
        })
      } else {
        this.auth.auth.signInWithPopup(new auth.GoogleAuthProvider());
      }
    });
  }

  prepareView(item) {
    this.report = item
    let consoleReport = ""
    item.report.forEach(e => {
      if (e.alert.length != 0) {
        consoleReport += e.name + '(' + e.alert.length + ')\n'
        e.alert.forEach(ee => {
          consoleReport += ee.no_operacion + ' ' + ee.fei + '\n'
        })
      }
    })
    console.log(consoleReport);
  }

  goToSearch(text: string) {
    $("#buttonClose").click();

    setTimeout(() => {
      this.userServ.goToWithParam("/", { textToSearch: text })
    }, 500)
  }
}
