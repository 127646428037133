import { Component, OnInit } from '@angular/core';
import { AlfrescoService } from '../services/alfresco'
import { CookieService } from '../services/cookie'
import { UserService } from '../services/user.service'
import { Observable } from 'rxjs';
import { ReportService, Report } from "../services/feiReport";

@Component({
  selector: 'app-fuseresend',
  templateUrl: './fuseresend.component.html',
  styleUrls: ['./fuseresend.component.css']
})
export class FuseresendComponent implements OnInit {
  users: Observable<string>;
  blusers: []
  loading: boolean = true

  constructor(
    private alfServ: AlfrescoService,
    private cookieServ: CookieService,
    private userServ: UserService,
    private repServ: ReportService,
  ) { }

  ngOnInit() {
    this.userServ.isAuthenticated()
    this.getPendingFuseResend()
  }

  remove() {
    this.users = this.alfServ.getPendingFuseResend(true)
    this.users.subscribe(result => {
      this.getPendingFuseResend()
    })
  }

  getPendingFuseResend() {
    this.users = this.alfServ.getPendingFuseResend(false)
    this.users.subscribe(result => {
      this.loading = false
      this.blusers = JSON.parse(result)
    })
  }

}
