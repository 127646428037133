import { Injectable } from '@angular/core';
import { Observable, } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AngularFireFunctions } from '@angular/fire/functions';
import { CookieService } from './cookie'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
};
const httpCheckoutOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'here' })
};
const httpOptionsFiles = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};


@Injectable({
  providedIn: 'root'
})
export class AlfrescoService {
  //SERVER_URL = 'http://us-central1-sites-259512.cloudfunctions.net/totalSearch?operacion=q61-9442';
  DIAGRAM_URL = 'https://cloud.totalcheck.cl/s/api/workflow-instances/'

  states = {
    "wfprd2:startEvent": "INICIO",
    "wfprd2:agregarFEITask": "MANDATO CARGADO",
    "wfprd2:mandatoAgregarTask": "ALERTA",
    "wfprd2:prdReviewTask": "EN PROCESO DE CONFECCION DE ESCRITURA",
    "wfprd2:generarDocFirmaTask": "EN PROCESO DE CONFECCION DE ESCRITURA",
    "wfprd2:aplicarFeaTask": "PENDIENTE FIRMA REPRESENTANTE",
    "wfprd3:processPendingTask": "TAREA PENDIENTE",
    "CANCELADO": "ANULADO"
  };

  constructor(
    private http: HttpClient,
    private fns: AngularFireFunctions,
    private cookieServ: CookieService
  ) { }

  /* getSites(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/people/michel.david/sites`, httpOptions);
  } */

  isValidUsers(): Observable<any> {
    const totalSearch = this.fns.httpsCallable('getRequest');
    return totalSearch({ url: "api/login/ticket/" + this.cookieServ.getCookie("ticket") + "?alf_ticket=" + this.cookieServ.getCookie("ticket") })
  }

  getBlockedUsers(): Observable<any> {
    const totalSearch = this.fns.httpsCallable('getRequest');
    return totalSearch({ url: "cl/dox/totalcheck/user-blocked" + "?alf_ticket=" + this.cookieServ.getCookie("ticket") })
  }

  getPendingFuseResend(clean:boolean): Observable<any> {
    const totalSearch = this.fns.httpsCallable('getRequest');
    return totalSearch({ url: "cl/dox/totalcheck/fuse-resend?clean="+clean + "&alf_ticket=" + this.cookieServ.getCookie("ticket") })
  }

  removeBlockedUsers(user: string): Observable<any> {
    const totalSearch = this.fns.httpsCallable('getRequest');
    return totalSearch({ url: "dox/blacklist?username=" + user + "&alf_ticket=" + this.cookieServ.getCookie("ticket"), ticket: this.cookieServ.getCookie("ticket") })
  }

  search(text): Observable<any> {
    const totalSearch = this.fns.httpsCallable('totalSearch');
    return totalSearch({ operacion: text, ticket: this.cookieServ.getCookie("ticket") })
  }

  task(task): Observable<any> {
    const totalSearch = this.fns.httpsCallable('taskSearch');
    return totalSearch({ operacion: task, ticket: this.cookieServ.getCookie("ticket") })
  }

  workflow(task): Observable<any> {
    const totalSearch = this.fns.httpsCallable('workflowSearch');
    return totalSearch({ operacion: task, ticket: this.cookieServ.getCookie("ticket") })
  }

  getDiagram(workflowId) {
    return this.DIAGRAM_URL + workflowId + "/diagram?alf_ticket=" + this.cookieServ.getCookie("ticket")
  }

  getEstadoPrenda(alfPrenda, tasks) {
    if (!alfPrenda)
      return "No existe"
    let estado = alfPrenda.properties['{http://www.dox.cl/model/content/common/1.0}estado'];

    if (estado == 'TERMINADO')
      estado = "FIRMADO POR REPRESENTANTE";
    else if (estado == 'CANCELADO')
      estado = "CANCELADO";
    else {
      let taskInfos = tasks || [];
      taskInfos.map((node) => {
        //console.log(node)
        if (node && node['{http://www.dox.cl/model/utils/workflow/1.0}taskState'] == 'CREATED') {
          const tn = node['{http://www.dox.cl/model/utils/workflow/1.0}taskName']
          estado = this.states[tn] || tn;
        }
      });
    }

    return estado;
  }
}