import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component'
import { ReportsComponent } from './reports/reports.component';
import { BloquedUsers } from './bloquedUsers/bloquedUsers.component';
import { DoxSignerComponent } from './dox-signer/dox-signer.component';
import { RPSDBotComponent } from './rpsd-bot/rpsd-bot.component';
import { DoxSignerDownloadComponent } from './dox-signer-download/dox-signer.component';
import { FuseresendComponent } from './fuseresend/fuseresend.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'blockedUsers',
    component: BloquedUsers
  },
  {
    path: 'doxsigner',
    component: DoxSignerComponent
  },
  {
    path: 'rpsd-bot',
    component: RPSDBotComponent
  },
  {
    path: 'doxsignerdownload',
    component: DoxSignerDownloadComponent
  },
  {
    path: 'fuseresend',
    component: FuseresendComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
