import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoxService, DoxReport } from '../services/doxSigner'
import { UserService } from '../services/user.service'
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dox-signer',
  templateUrl: './dox-signer.component.html',
  styleUrls: ['./dox-signer.component.css']
})
export class DoxSignerDownloadComponent implements OnInit {

  section: string = ""
  reports: Observable<DoxReport[]>;
  data: string;

  constructor(
    private route: ActivatedRoute,
    private doxSrv: DoxService,
    private userServ: UserService,
  ) { }

  ngOnInit() {
    //this.userServ.isAuthenticated()
    this.route.queryParams.subscribe(params => {
      this.section = params['id'];
      console.log(this.section)
      this.doxSrv.getById(this.section).subscribe(result => {
        this.data = JSON.stringify(result)
      })
    })
  }

}
