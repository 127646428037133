import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AlfrescoService } from './services/alfresco';
import { DoxService } from './services/doxSigner';
import { RPSDBotService } from './services/rpsdBot';
import { CookieService } from './services/cookie';
import { HomeComponent } from './home/home.component';
import { ReportsComponent } from './reports/reports.component';
import { BloquedUsers } from './bloquedUsers/bloquedUsers.component';

//firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireFunctions } from '@angular/fire/functions';
//import * as firebaseui from 'firebaseui';
import { environment } from '../environments/environment';
import { LeftBarComponent } from './left-bar/left-bar.component';
import { DoxSignerComponent } from './dox-signer/dox-signer.component';
import { RPSDBotComponent } from './rpsd-bot/rpsd-bot.component';
import { DoxSignerDownloadComponent } from './dox-signer-download/dox-signer.component'
import { FuseresendComponent } from './fuseresend/fuseresend.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ReportsComponent,
    BloquedUsers,
    LeftBarComponent,
    DoxSignerComponent,
    RPSDBotComponent,
    DoxSignerDownloadComponent,
    FuseresendComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
  ],
  providers: [
    DoxService,
    RPSDBotService,
    AlfrescoService,
    CookieService,
    AngularFirestore
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
