import { Component, OnInit } from '@angular/core';
import { AlfrescoService } from '../services/alfresco'
import { CookieService } from '../services/cookie'
import { UserService } from '../services/user.service'
import { Observable } from 'rxjs';
import { ReportService, Report } from "../services/feiReport";
import { element } from 'protractor';

@Component({
  selector: 'app-bloquedUsers',
  templateUrl: './bloquedUsers.component.html',
  styleUrls: ['./bloquedUsers.component.css']
})
export class BloquedUsers implements OnInit {

  users: Observable<string>;
  blusers: []
  loading: boolean = true

  constructor(
    private alfServ: AlfrescoService,
    private cookieServ: CookieService,
    private userServ: UserService,
    private repServ: ReportService,
  ) { }

  ngOnInit() {
    this.userServ.isAuthenticated()
    this.getBlockedUsers()
  }

  remove(item: string) {
    this.alfServ.removeBlockedUsers(item).subscribe(result => {
      this.getBlockedUsers()
    })
  }

  getBlockedUsers() {
    this.users = this.alfServ.getBlockedUsers()
    this.users.subscribe(result => {
      this.loading = false
      this.blusers = JSON.parse(result)
    })
  }

}
