import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RPSDBotService, RPSDBOTReport } from '../services/rpsdBot'
import { UserService } from '../services/user.service'
import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Component({
  selector: 'app-rpsd-bot',
  templateUrl: './rpsd-bot.component.html',
  styleUrls: ['./rpsd-bot.component.css']
})
export class RPSDBotComponent implements OnInit {

  section: string = ""
  reports: Observable<RPSDBOTReport[]>;
  data: RPSDBOTReport[] = [];

  constructor(
    private route: ActivatedRoute,
    private doxSrv: RPSDBotService,
    private userServ: UserService,
    public auth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.userServ.isAuthenticated()
    this.route.params.subscribe(params => {
      this.section = params['id'];
      console.log(this.section)
      this.auth.auth.onAuthStateChanged((user) => {
        if (user) {
          this.reports = this.doxSrv.getAll()
          this.reports.subscribe(result => {
            this.data = result
            console.log(result)
          });
        } else {
          this.auth.auth.signInWithPopup(new auth.GoogleAuthProvider());
        }
      })
    })
  }

}
