import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FireModel } from './model';


export interface DoxReport {
    version: string;
    path: string;
    client: string;
    tenant: string;
    config: Object;
}

@Injectable({
    providedIn: 'root'
})

export class DoxService extends FireModel<DoxReport>{
    constructor(
        public db: AngularFirestore
    ) {
        super('dox-signer', db)
    }

    toString() {
        return ""
    }

    toJson() {
        return {}
    }
}
